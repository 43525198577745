@mixin placeholder($color: '') {
	&::-webkit-input-placeholder {
		@if $color != '' {
			-webkit-text-fill-color: $color;
		}
	}
	&:-moz-placeholder {
		@if $color != '' {
			-webkit-text-fill-color: $color;
		}
	}
	&::-moz-placeholder {
		@if $color != '' {
			-webkit-text-fill-color: $color;
		}
	}
	&:-ms-input-placeholder {
		@if $color != '' {
			-webkit-text-fill-color: $color;
		}
	}
}
