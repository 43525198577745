@import 'base.scss';

.textarea,
.disabled {
	background: $white;
	border-radius: 4px;
	border: 1px solid $grey-90;
	font-size: 12px;
	height: 100px;
	overflow: auto;
	resize: none;
	vertical-align: top;
	width: 100%;

	&:active,
	&:focus {
		border-color: $blue-primary-50;
	}
}

.disabled {
	cursor: not-allowed;
	height: auto;

	&:hover {
		border-color: transparent;
	}
}
