@import-normalize;

@font-face {
	font-family: icon;
	src: url('fonts/icon/icon.eot?ews0yg');
	src: url('fonts/icon/icon.eot?ews0yg#iefix') format('embedded-opentype'),
		url('fonts/icon/icon.ttf?ews0yg') format('truetype'),
		url('fonts/icon/icon.woff?ews0yg') format('woff'),
		url('fonts/icon/icon.svg?ews0yg#icon') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: Wotfard;
	src: local('wotfard-regular-webfont'),
		url('fonts/wotfard/wotfard-regular-webfont.eot') format('embedded-opentype'),
		url('fonts/wotfard/wotfard-regular-webfont.ttf') format('truetype'),
		url('fonts/wotfard/wotfard-regular-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: Wotfard;
	src: local('wotfard-semibold-webfont'),
		url('fonts/wotfard/wotfard-semibold-webfont.eot') format('embedded-opentype'),
		url('fonts/wotfard/wotfard-semibold-webfont.ttf') format('truetype'),
		url('fonts/wotfard/wotfard-semibold-webfont.woff2') format('woff2');
	font-weight: bold;
	font-style: normal;
}

@import 'base.scss';

html,
body,
#root {
	position: relative;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	font-size: 100%;
	overflow-x: hidden;
	word-wrap: break-word;
	word-break: break-word;
	font-variant-numeric: tabular-nums;
}

html {
	overflow: hidden;
}

html,
html * {
	font-family: Wotfard, sans-serif;
}

#root {
	z-index: 1;
}

a {
	color: inherit;
	user-select: none;
	text-decoration: none;
}

ul {
	margin: 0;
	padding: 0;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

*:focus,
*:active {
	outline: none;
	border: none;
	text-decoration: none;
}

[class*='_hj'] {
	:after,
	:before {
		position: initial;
	}
}

.clearfix {
	&:before {
		content: '';
		display: table;
		margin: 0;
		padding: 0;
	}
	&:after {
		content: '';
		display: table;
		margin: 0;
		padding: 0;
		clear: both;
	}
}

@media print {
	html,
	body,
	#appRoot {
		overflow-x: initial;
		height: auto;
	}
}

@import 'style';
@import 'themes/mediaq';
