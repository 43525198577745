@import 'base.scss';

.wrapper {
	font-size: 12px;

	&--inline {
		display: flex;
		gap: 20px;
	}
}

.option__wrapper {
	align-items: center;
	cursor: pointer;
	display: flex;
	padding: 5px 0;
}

.label-dark {
	color: $white;
}

.label-light,
.label-blue {
	color: $grey-10;
}

.description {
	color: $grey-45;
	padding-left: 30px;

	&__disabled {
		padding: 0;
	}
}

.toggle-dark,
.toggle-light,
.toggle-blue {
	border-radius: 10px;
	border: 1px solid $white;
	height: 20px;
	margin-right: 10px;
	width: 20px;
	padding: 5px;
}

.toggle-light {
	border-color: $grey-10;
}

.toggle-blue {
	border-color: $grey-90;

	&--selected {
		border: 2px solid $blue-primary-45;
		border-color: $blue-primary-45;
	}
}

.selected-dark,
.selected-light,
.selected-blue {
	background: $white;
	border-radius: 10px;
	height: 8px;
	width: 8px;
}

.selected-light {
	background: $grey-10;
}

.selected-blue {
	position: relative;
	background: $blue-primary-45;
	height: 10px;
	width: 10px;
	top: -2px;
	left: -2px;
}

.disabled {
	cursor: not-allowed;
}
