@import 'base.scss';

.wrapper {
	font-size: 12px;
}

.row {
	display: grid;
	grid-template-columns: 1fr min-content;
	white-space: nowrap;
	gap: 8px;

	&__option_label {
		padding-left: 8px;
	}

	&__code {
		text-align: center;
	}
}
