@import 'base.scss';

.split {
	display: grid;
	gap: 8px;
	grid-template-columns: 1fr 2fr;
}

.contains {
	border-radius: 5px;
	border: 1px solid $grey-90;
	color: $grey-10;
	font-size: 12px;
	height: 30px;
	overflow: hidden;
	padding: 7px;
	pointer-events: none;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}
