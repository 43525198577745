@import 'base.scss';

@import 'base.scss';

.wrapper {
	align-items: center;
	display: flex;
	height: 100%;
}

.tab {
	display: flex;
	gap: 4px;
	align-items: center;
	height: 100%;
	font-size: 12px;
	line-height: 16px;
	cursor: pointer;
	border-bottom: 2px solid transparent;
	white-space: nowrap;

	&:hover {
		border-bottom: 2px solid $grey-90;
	}

	&--disabled {
		color: $grey-60;
		cursor: not-allowed;

		&:hover {
			border-bottom: 2px solid transparent;
		}
	}

	&--active {
		color: $blue-primary-35;
		border-bottom: 2px solid $blue-primary-45;

		&:hover {
			border-bottom: 2px solid $blue-primary-45;
		}
	}

	&__error {
		background-color: $red-50;
		border-radius: 50%;
		height: 6px;
		width: 6px;
	}
}
