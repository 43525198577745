@import 'base.scss';

.code {
	font-family: monospace;
	height: 300px;
	min-height: 300px;
	resize: vertical;
}

.section-name-label {
	font-size: 18px;
	padding-top: 20px;
}
