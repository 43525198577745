.study-state {
	&-ARCHIVED {
		background-color: $study-state-archived-bg;
		color: $study-state-archived;
		border: 1px solid $study-state-archived-bg;
	}

	&-DRAFT,
	&-LIBRARY,
	&-READY_TO_LAUNCH {
		background-color: $study-state-draft-bg;
		color: $study-state-draft;
		border: 1px solid $study-state-draft-bg;
	}

	&-LAUNCHED,
	&-FEEDING_BOTS {
		background-color: $study-state-launched-bg;
		color: $study-state-launched;
		border: 1px solid $study-state-launched-bg;
	}

	&-PAUSED {
		background-color: $study-state-paused-bg;
		color: $study-state-paused;
		border: 1px solid $study-state-paused-bg;
	}

	&-SOLVED,
	&-POST_ANALYTICS {
		background-color: $study-state-solved-bg;
		color: $study-state-solved;
		border: 1px solid $study-state-solved-bg;
	}

	&-ERROR {
		background-color: $study-state-error-bg;
		color: $study-state-error;
		border: 1px solid $study-state-error-bg;
	}
}
