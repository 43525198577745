@import 'base.scss';

// make input field 100% width of the parent, without breaking calendar
.calendar-wrapper {
	ul {
		@extend .scrollbar-thin;
	}
}

.calendar-wrapper > div:nth-of-type(1) {
	display: block;
}
.calendar-wrapper > div:nth-of-type(1) > div {
	display: block;
}

.time {
	align-items: center;
	display: flex;
	font-size: 12px;
}
