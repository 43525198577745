@import 'base.scss';

.input {
	background: $white;
	border: 1px solid $grey-90;
	border-radius: 5px;
	font-size: 12px;
	padding: 7px;
	width: 100%;

	&:active,
	&:focus {
		border-color: $blue-primary-50;
	}

	&::placeholder {
		opacity: 1;
	}

	&--invalid {
		border-color: $red-40;
	}
}

.disabled {
	cursor: not-allowed;

	&:active,
	&:focus {
		border-color: $grey-90;
	}
}
