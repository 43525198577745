@import 'base.scss';

.wrapper {
	font-size: 12px;
	padding: 8px;
}

.label,
.row {
	padding: 2px 0;
}

.label {
	font-weight: 700;
}

.url {
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	cursor: default;
	text-overflow: ellipsis;
}
