@import 'base.scss';

.grid-wrapper {
	display: grid;
	font-size: 12px;
	gap: 8px;
	grid-template-columns: 1fr 24px min-content;
	white-space: nowrap;

	&__label {
		align-items: center;
		display: flex;
		padding-left: 8px;
		white-space: pre-wrap;
	}

	&__icon {
		align-items: center;
		cursor: pointer;
		display: flex;
		justify-content: center;

		&--disabled {
			cursor: not-allowed;
		}
	}

	&__code {
		align-items: center;
		display: flex;
		justify-content: center;
	}
}
