@import './color.scss';

.markdown {
	del {
		text-decoration: line-through;
	}

	strong {
		font-weight: bold;
	}

	mark {
		background-color: $yellow-55;
		color: $grey-10;
	}

	em {
		font-style: italic;
	}

	sub {
		vertical-align: sub;
		font-size: smaller;
	}

	sup {
		vertical-align: super;
		font-size: smaller;
	}

	ins {
		text-decoration: underline;
	}
}
