@import 'base.scss';

.wrapper {
	display: flex;
	gap: 24px;
	align-items: center;
	height: 100%;
	margin-left: 24px;
}

.divider {
	height: 16px;
	border-right: 1px solid $grey-90;

	@media screen and (#{$max-width-736}) {
		display: none;
	}
}

.spacer {
	display: none;

	@media screen and (#{$max-width-1300}) {
		display: block;
		border: 1px solid transparent;
	}
}
