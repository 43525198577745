@import 'base.scss';

.wrapper {
	display: flex;
	flex-direction: column;
}

.icon {
	color: $blue-primary-50;
	margin: 0 auto;
	margin-bottom: 10px;
}

.link {
	color: $blue-primary-45;
}
