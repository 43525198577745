@import 'base.scss';

.code-label-valid,
.code-label-invalid {
	padding-left: 20px;
	position: relative;

	&:after {
		@extend .icon-check;
		background-color: $blue-primary-45;
		border-radius: 50%;
		color: $white;
		font-size: 8px;
		height: auto;
		left: 0;
		padding: 3px;
		top: 10px;
		width: auto;
	}
}

.code-label-invalid {
	&:after {
		@extend .icon-cross;
		background-color: $red-40;
		color: $white;
	}
}
