@import 'base.scss';

.split {
	display: grid;
	gap: 8px;
	grid-template-columns: 1fr 2fr;
}

.input-with-icon {
	position: relative;
	padding: 2px 0;

	&__input {
		padding-right: 25px;
	}

	&__icon {
		align-items: center;
		cursor: pointer;
		display: flex;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
	}
}
