@import 'base.scss';

.divider {
	margin-right: -$flow-detail-padding;
	padding-left: $flow-detail-padding-options;
	padding-right: $flow-detail-padding-options;
	height: 1px;
	margin-left: -$flow-detail-padding;
	margin-top: 16px;
	width: calc(100% + #{$flow-detail-padding} + #{$flow-detail-padding});

	&--message {
		background: $grey-90;
	}

	&--question {
		background: $blue-primary-30;
	}
}
