@import 'base.scss';

.wrapper {
	display: inline-block;
}

.holder {
	cursor: pointer;
	font-size: 12px;
	display: grid;
	grid-template: 1fr / 25px 1fr;
	gap: 10px;
	align-items: center;
	padding-top: 5px;
}

.icon-holder {
	background: $input-background;
	border-radius: 3px;
	border: 1px solid transparent;
	height: 25px;
	position: relative;
	width: 25px;

	&:after {
		font-size: 10px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}
