@import 'base.scss';

.holder {
	background: rgba($white, 0.95);
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;

	&--is-opaque {
		background-color: $white;
	}

	&--without-z-index {
		z-index: auto;
	}
}
