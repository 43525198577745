@import 'base.scss';

.header {
	display: grid;
	grid-template-columns: minmax(50px, auto) 30px;
	justify-content: space-between;
	padding: 10px 0;
}

// TODO:
// unused
.description {
	background: $input-background;
	border-radius: 5px;
	border: 1px solid transparent;
	color: $grey-10;
	font-size: 12px;
	padding: 7px;
}

.operator {
	background: $grey-90;
	border-radius: 5px;
	border: 1px solid $grey-90;
	color: $grey-10;
	font-size: 12px;
	font-weight: 700;
	height: 30px;
	overflow: hidden;
	padding: 7px;
	pointer-events: none;
	text-align: center;
	text-transform: uppercase;
	width: auto;

	&__inactive {
		cursor: default;
	}

	&:active,
	&:focus {
		border: 1px solid $grey-90;
	}
}

.btn-remove {
	align-items: center;
	color: inherit;
	cursor: pointer;
	display: flex;
	opacity: 0.5;
	position: relative;
	transition: opacity 0.5s;

	&:hover {
		opacity: 1;
	}
}

.definition {
	display: grid;
	gap: 10px;
	grid-template-columns: 1fr 1fr;
	padding: 10px 0;

	&__no-answer-checkbox,
	&__oeq {
		grid-column: 1 / span 2;
	}
}

.met-label {
	border-radius: 5px;
	border: 1px solid $grey-90;
	color: $grey-10;
	font-size: 12px;
	height: 30px;
	overflow: hidden;
	padding: 7px;
	pointer-events: none;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;

	&:active,
	&:focus {
		border: 1px solid $grey-90;
	}
}

.message {
	text-align: left;
}

.settings {
	&__holder {
		padding-bottom: 10px;
	}
}

.oeq-info-holder {
	grid-column: 1 / span 2;
}

.condition-any-of {
	padding: 2px 0;
	position: relative;

	&__input {
		padding-right: 25px;
	}

	&__remove {
		cursor: pointer;
		height: 15px;
		position: absolute;
		right: 5px;
		top: 50%;
		transform: translateY(-50%);
		width: 15px;

		&:after {
			@extend .icon-minus;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.allocation-ranking-values {
	display: flex;
	gap: 10px;
}

.options {
	margin-top: 10px;
}
