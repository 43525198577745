@import 'base.scss';

.overlay {
	padding: 0;
	max-height: calc(100vh - 40px);
}

.content {
	padding: 20px;
}

.grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 8px;
	font-size: 12px;
}

.error {
	grid-column: 1 / 3;
	margin: 0;
}

.label {
	display: flex;
	align-items: center;
}

.buttons {
	display: flex;
	justify-content: space-between;
	padding-top: 8px;
}
