@import 'base.scss';

.uploader {
	display: none;
	height: 0;
	width: 0;
}

.upload {
	&__holder {
		align-items: center;
		background: $white;
		border-radius: 5px;
		border: 1px solid $grey-90;
		cursor: default;
		display: grid;
		font-size: 12px;
		gap: 8px;
		grid-template-columns: min-content 1fr min-content min-content;
		height: $interactive-element-default-height;
		margin-top: 8px;
		padding: 0 7px;
		white-space: nowrap;
	}

	&__filename {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__label {
		color: $blue-primary-35;
		cursor: pointer;
		padding: 7px 0;
	}

	&__manage {
		cursor: pointer;
	}
}
