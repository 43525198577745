@import 'base.scss';

.holder-header-detail {
	position: relative;
	min-height: 85px;
	padding: 15px 15px 0;
	box-shadow: 0 5px 5px -5px rgba($grey-10, 0.12) inset;
}

.allocation,
.choice,
.heatmap,
.image-choice,
.max-diff,
.open-question,
.free-text,
.ranking {
	color: $white;
	background: $blue-primary-45;
}

.if,
.snippet,
.end-study,
.list {
	color: $grey-10;
	background: $white;
	border-bottom: 1px dashed $grey-60;
}

.message,
.welcome-screen {
	color: $grey-10;
	background: $white;
}

.actions {
	align-items: center;
	display: flex;
	gap: 4px;

	&__close {
		margin-right: auto;
	}

	&__language {
		margin-left: auto;
	}

	&__select {
		&--white {
			color: $white;
			background: transparent;

			&:hover {
				background: $blue-primary-35;
			}
		}
	}
}

.module-intro {
	position: relative;
	padding: 15px 0;
}

.module-name {
	align-items: center;
	color: inherit;
	display: flex;
	font-size: 18px;
	margin-bottom: 10px;

	&__help {
		align-items: center;
		border-radius: 50%;
		border: 1px solid;
		cursor: pointer;
		display: flex;
		font-size: 10px;
		height: 15px;
		justify-content: center;
		margin-left: 5px;
		position: relative;
		width: 15px;
	}
}

.module-description {
	display: block;
	color: inherit;
	font-size: 12px;
}

.btn-return {
	align-self: center;
	border-radius: 10px;
	border-style: solid;
	border-width: 1px;
	color: inherit;
	font-size: 10px;
	height: 20px;
	opacity: 0.7;
	padding: 0 12px 0 25px;
	transition: opacity 0.5s;

	&:after {
		@extend .icon-cross;
		font-size: 10px;
		left: 7px;
		top: 50%;
		transform: translateY(-50%);
	}

	&:hover {
		opacity: 1;
	}
}

.language_select {
	&__action {
		background: $white;
	}

	&__error {
		color: $red-40;
	}
}
