@import 'base.scss';

.disabled {
	color: $grey-60;
	position: relative;

	&__blocker {
		position: absolute;
		inset: 0;
		cursor: not-allowed;
	}
}
