@import 'base.scss';

.wrapper {
	padding-bottom: 10px;
	padding: 6px 0;
	position: relative;
	margin-left: -$flow-detail-padding-options;
	margin-right: -$flow-detail-padding-options;
	padding-left: $flow-detail-padding-options;
	padding-right: $flow-detail-padding-options;

	&--expanded {
		background-color: $blue-primary-30;
	}
}

.row {
	align-items: center;
	display: grid;
	gap: $flow-detail-question-columns-gap;
	grid-template-columns: $flow-detail-question-options-columns;

	&--with-image {
		grid-template-columns: $flow-detail-image-choice-options-columns;
	}

	&--max-diff {
		grid-template-columns: $flow-detail-max-diff-options-columns;
	}

	&--max-diff-image {
		grid-template-columns: $flow-detail-max-diff-image-options-columns;
	}

	&__single-item {
		grid-column: 2 / 4;
	}
}

.error {
	position: relative;
}

.code-input {
	text-align: center;
}

.drag {
	align-items: center;
	cursor: grab;
	display: flex;
	justify-content: center;

	&__icon {
		fill: $white;
	}
}

.toggle-expanded {
	color: $white;
	cursor: pointer;
}

.wrapper-image {
	width: 48px;
	height: 48px;

	&__placeholder {
		border: 1px solid $blue-primary-30;
		height: 48px;
		width: 48px;
	}
}

.input__wrapper {
	position: relative;
}

.input {
	overflow: hidden;
	padding-right: 55px;
	text-overflow: ellipsis;
	white-space: nowrap;

	&--max-diff {
		padding-right: 30px;
	}

	&--hidden {
		opacity: 0.8;
	}
}

.actions {
	align-items: center;
	color: $grey-10;
	display: flex;
	flex-direction: row-reverse;
	gap: 4px;
	height: 100%;
	position: absolute;
	right: 4px;
	top: 0;
}

.disabled {
	cursor: not-allowed;

	&:hover {
		opacity: 0.5;
	}
}

.generate-id {
	cursor: pointer;
}
