@import 'base.scss';

.active {
	z-index: 1000;
}

.wrapper {
	position: relative;
}

.menu {
	position: absolute;
	z-index: 1000;

	&--bottom-right {
		top: calc(100% + 2px);
	}

	&--bottom-center {
		top: calc(100% + 2px);
		left: 50%;
		transform: translate(-50%, 0);
	}

	&--bottom-left {
		top: calc(100% + 2px);
		right: 0;
	}

	&--top {
		bottom: 100%;
		transform: translateY(-2px);
	}

	&--top-centered {
		left: 50%;
		bottom: 100%;
		transform: translate(-50%, -2px);
	}

	&--top-left {
		bottom: calc(100% - 2px);
		right: 0;
	}

	&--right {
		left: 100%;
		top: 0;
	}

	&--left {
		top: 0;
		right: 100%;
	}

	&--center {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&--left-centered {
		top: -100%;
		right: 100%;
	}
}
