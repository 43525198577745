@import 'base.scss';

.wrapper {
	display: flex;
	padding: 10px 0;

	&--with-scale {
		padding: 0;
	}
}

.btn--minus,
.btn--plus {
	cursor: pointer;
	position: relative;
	background-color: $input-background;
	border: 1px solid transparent;
	border-radius: 3px;
	height: 16px;
	width: 16px;
	opacity: 0.5;
	transition: opacity 0.5s;

	&:hover {
		opacity: 1;
		border-color: $white;
	}

	&:after {
		@extend .icon-minus;
		font-size: 8px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.btn--plus {
	&:after {
		@extend .icon-plus;
	}
}

.btn--disabled {
	position: relative;
	border: 1px solid transparent;
	height: 16px;
	width: 16px;
}

// TODO
// remove when .rc-slider starts working again
.slider-holder {
	margin: 0 8px;
	flex-grow: 1;

	&--spacer {
		margin: 0 15px;
	}

	&--disabled {
		margin: 8px 0;
	}
}

// Basic Slider styles
.rc-slider {
	// TODO uncommnet after removing .slider-holder
	// margin: 0 8px;

	// &--spacer {
	// 	margin: 0 15px;
	// }

	&--disabled {
		// margin: 8px 0;
		cursor: not-allowed;
		background-color: transparent;
	}

	&__range {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $grey-45;
		font-size: 10px;
		margin: 8px 20px;

		&--are-buttons-hidden {
			margin: 0;
		}
	}

	&__scale {
		position: relative;
		margin: 8px 20px;
		padding-bottom: 10px;

		&--wrapper {
			margin: 28px 8px;
			cursor: default;
		}

		&--are-buttons-hidden {
			margin: 8px 6px;
		}

		&--number {
			color: $grey-60;
			cursor: pointer;
			font-size: 10px;
			position: absolute;
			text-align: center;
			white-space: nowrap;

			&__first {
				text-align: left;
			}

			&__last {
				text-align: right;
			}

			&__disabled {
				cursor: not-allowed;
			}
		}
	}
}
