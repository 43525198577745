@import 'base.scss';

.holder {
	padding-top: 10px;
}

.name {
	font-size: 14px;
	font-weight: 700;
	overflow: hidden;
	padding-top: 10px;
	text-overflow: ellipsis;
	width: 100%;
}

.code {
	height: 200px;
}
