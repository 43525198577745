@import 'base.scss';

.wrapper {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
}

.holder {
	display: flex;
	align-items: center;
	font-size: 12px;

	&--with-text {
		width: 150px;
	}

	&--without-text {
		min-width: 16px;
		min-height: 16px;
		cursor: pointer;
	}
}

.thumbnail-wrapper {
	display: flex;
	align-items: center;
	color: $blue-primary-35;

	&__default-image,
	&__image-choice-option {
		display: flex;
		align-items: center;
		overflow: hidden;
	}

	&__default-image {
		width: 32px;
		height: 32px;
	}

	&__image-choice-option {
		width: 48px;
		height: 48px;

		img {
			width: 100%;
		}
	}
}

.image {
	width: 100%;
}

.image,
.placeholder {
	border-radius: 4px;
}

.placeholder {
	align-items: center;
	cursor: pointer;
	display: flex;
	justify-content: center;
	position: relative;

	&--disabled {
		cursor: default;
	}
}
