@import 'base.scss';

.overlay {
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 40px);
	max-width: calc(100vw - 40px);
	overflow: hidden;
	padding: 0;
}

.content {
	flex-grow: 1;
	font-size: 12px;
	max-height: 100%;
	max-width: 100%;
	overflow: auto;
	padding: 0 20px 20px;
}

.table {
	border-collapse: collapse;
	padding: 10px;
	text-align: left;

	&__thead {
		background: $white;
		position: sticky;
		top: 0;
		z-index: 2;
	}

	&__tr {
		&:nth-of-type(odd) {
			background: $grey-95;

			.randomize-toggle {
				&:hover {
					background: $white;
				}

				&--disabled {
					&:hover {
						background: none;
					}
				}
			}
		}
	}

	&__th {
		cursor: default;

		&--clickable {
			cursor: pointer;
		}
	}

	&__td,
	&__th {
		white-space: pre;
	}

	&__td {
		padding: 0 10px;

		&--hidden {
			color: $grey-60;
		}
	}

	&__th {
		padding: 10px;
	}
}

.is-hidden-toggle {
	align-items: center;
	cursor: pointer;
	display: flex;
	justify-content: center;
}

.randomize-toggle {
	margin: 0 auto;
}

.randomize_all {
	font-weight: 700;
}
