@import 'base.scss';

.holder-status {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100%;
	flex: 1;
	padding: 20px;

	&-loading {
		.description-status,
		.title-status {
			color: $blue-primary-45;
		}
	}

	&-offline,
	&-nodata,
	&-failed {
		.wrapper-status {
			padding-top: 50px;

			&:after {
				color: $grey-10;
				font-size: 50px;
				top: 0;
				left: calc(50% - 25px);
			}
		}

		.description-status,
		.title-status {
			color: $grey-10;
		}
	}

	&-failed {
		.wrapper-status {
			@extend .icon-emoji-dead-full;
		}
	}

	&-nodata {
		.wrapper-status {
			@extend .icon-no-data;
		}
	}

	&-offline {
		.wrapper-status {
			@extend .icon-emoji-dead-outline;
		}
	}
}

.wrapper-status {
	position: relative;
	text-align: center;
	max-width: 240px;
}

.description-status,
.title-status {
	color: $grey-60;
	display: block;
	font-weight: bold;
	font-size: 12px;

	&--big {
		font-size: 24px;
	}
}

.description-status {
	font-style: italic;
}

.wrapper-loader {
	padding: 10px 0;
}
