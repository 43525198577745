@import 'base.scss';

.disabled {
	cursor: not-allowed;
}

.action {
	align-items: center;
	border-radius: 4px;
	border: 1px solid transparent;
	cursor: pointer;
	display: flex;
	font-size: 12px;
	font-weight: normal;
	line-height: 16px;
	white-space: nowrap;
	flex-shrink: 0;

	// changed by size preset
	gap: 8px;
	// TODO use $interactive-element-default-height
	height: 30px;
	padding: 0 10px;

	&--with-icon {
		padding-left: 6px;
	}

	&--with-dropdown-indicator {
		padding-right: 6px;
	}

	// changed by preset
	background: transparent;
	color: $grey-10;

	&:hover {
		background: $grey-95;
	}

	&--active {
		background: $grey-95;
		color: $blue-primary-35;
	}

	&--disabled {
		background: transparent;
		color: $grey-60;
		cursor: not-allowed;

		&:hover {
			background: transparent;
			color: $grey-60;
		}
	}
}

.action-small {
	gap: 4px;
	height: $interactive-element-small-height;
	padding: 0 8px;

	&--with-icon {
		padding-left: 4px;
	}

	&--with-dropdown-indicator {
		padding-right: 4px;
	}
}

.action-primary {
	background: $blue-primary-95;
	color: $blue-primary-35;

	&:hover,
	&--active {
		background: $blue-primary-95;
		border-color: $blue-primary-90;
	}

	&--disabled {
		color: $grey-60;
		background: $grey-95;
		border-color: transparent;

		&:hover {
			color: $grey-60;
			background: $grey-95;
			border-color: transparent;
		}
	}
}

.action-negative {
	background: $red-95;
	color: $red-30;

	&:hover,
	&--active {
		background: $red-95;
		border-color: $red-90;
	}

	&--disabled {
		color: $grey-60;
		background: $grey-95;
		border-color: transparent;

		&:hover {
			color: $grey-60;
			background: $grey-95;
			border-color: transparent;
		}
	}
}

.action-positive {
	background: $green-95;
	color: $green-30;

	&:hover,
	&--active {
		background: $green-95;
		border-color: $green-95;
	}

	&--disabled {
		color: $grey-60;
		background: $grey-95;
		border-color: transparent;

		&:hover {
			color: $grey-60;
			background: $grey-95;
			border-color: transparent;
		}
	}
}

.action-tag {
	background: $grey-95;
	color: $grey-45;

	&:hover {
		background: $grey-95;
		color: $grey-45;
	}
}

.action-warning {
	background: $white;
	border: 1px solid $orange-90;
	color: $yellow-35;
}

.icon-only {
	aspect-ratio: 1;
	justify-content: center;
	padding: 0;
}
