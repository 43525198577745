body {
	background-color: $white;
	line-height: normal;
}

*:after,
*:before {
	position: absolute;
	font-family: icon, serif;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-feature-settings: 'tnum' on, 'lnum' on;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@import 'themes/title';
@import 'themes/buttons';
@import 'themes/hidden';
@import 'themes/markdown';
@import 'charts/index';
@import 'themes/studyState';
@import 'themes/scrollbar';

.clickable {
	cursor: pointer;
}

.content-row {
	position: relative;
	display: flex;
	flex: 1;
}

.recharts-text {
	font-size: 14px;
}

.content-column {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
}

.content-min-height {
	min-height: 100%;
}

.input-form {
	@extend .title-secondary;

	background-color: $white;
	border-radius: 5px;
	border: 1px solid $grey-90;
	outline: none;
	color: inherit;
	width: 100%;
	padding: 7px;
	height: 30px;
	transition: border-color 0.5s;

	&:active,
	&:focus {
		border-color: $blue-primary-50;
	}
}

.textarea-form {
	@extend .title-secondary;
	background-color: $white;
	vertical-align: top;
	color: inherit;
	resize: none;
	overflow: auto;
	border: 1px solid $grey-90;
	outline: none;
	border-radius: 3px;
	width: 100%;
	height: 100px;
	padding: 5px 7px;
	transition: border-color 0.5s;

	&:active,
	&:focus {
		border-color: $blue-primary-50;
	}
}

.holder-select {
	display: inline-block;
	vertical-align: middle;
	max-width: 250px;
	width: 100%;
}

[placeholder] {
	@include placeholder(rgba(0, 0, 0, 0.35));
}

input[disabled],
textarea[disabled] {
	cursor: not-allowed;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	border: none;
	-webkit-text-fill-color: inherit;
	-webkit-box-shadow: 0 0 0 1000px rgb(216, 243, 253) inset;
	transition: background-color 5000s ease-in-out 0s;
}

@media screen and (#{$max-width-1110}) {
	.content-min-height {
		min-height: auto;
	}
}

@media screen and (#{$max-width-500}) {
	.holder-select {
		max-width: 100%;
		flex: 1;
	}
}
