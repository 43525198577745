@import 'base.scss';

.id-holder {
	color: $grey-10;
	cursor: pointer;
	display: inline;

	&--copied {
		cursor: initial;
		font-weight: 700;
	}
}
