@import 'base.scss';

.tooltip {
	min-width: 140px;
	max-width: 250px;
	border-radius: 5px;
	font-size: 12px;
	padding: 12px;
	text-align: center;
}
