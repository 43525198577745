@import 'base.scss';

.option {
	color: $grey-10;
	cursor: pointer;
	border-radius: 3px;
	font-size: 12px;
	padding: 10px 7px;

	&:hover {
		background: $grey-90;
	}
}

.disabled {
	background: $grey-95;
	color: $grey-60;
	cursor: not-allowed;

	&:hover {
		background: $grey-95;
	}
}

.tooltip {
	width: 110px;
	white-space: initial;
}
