@import 'themes/color.scss';

.scrollbar-thin {
	// firefox
	scrollbar-width: thin;

	// webkit
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background: $grey-90;
	}

	&::-webkit-scrollbar-thumb {
		width: 5px;
		background: $grey-60;
	}
}
