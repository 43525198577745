@import 'base.scss';

$large-min-width: 67px;
$default-min-width: 53px;

.button {
	position: relative;
	background: transparent;
	cursor: pointer;
	text-align: center;
	user-select: none;
	vertical-align: middle;
	border: 1px solid;
	border-radius: 4px;
	font-size: 12px;
	margin: 0;
	padding: 0 8px;
	white-space: nowrap;
	// TODO use $interactive-element-default-height when we
	// switch all interactive elements to 32px
	height: 30px;
	// height: $interactive-element-default-height;
	min-width: $default-min-width;

	@media screen and (#{$max-width-736}) {
		font-size: 11px;
	}

	&--qhd {
		border-radius: 8px;
		font-size: 24px;
		padding: 0 16px;
		height: 60px;
	}
}

.button__big {
	height: $interactive-element-big-height;
	min-width: $large-min-width;
	padding: 9px 12px;
	font-size: 14px;

	@media screen and (#{$max-width-736}) {
		font-size: 11px;
	}
}

.button__primary {
	background: $blue-primary-45;
	border-color: $blue-primary-45;
	color: $white;
	text-shadow: 0.55px 0 0 $white;

	&:hover {
		background: linear-gradient(0deg, rgba($grey-10, 0.1), rgba($grey-10, 0.1)), $blue-primary-45;
		border-image: linear-gradient(0deg, rgba($blue-primary-35, 1), rgba($blue-primary-35, 0));
	}

	&:focus {
		background: $blue-primary-45;
		border-color: $blue-primary-35;
		box-shadow: $box-shadow-button-focused;
		border-image: none;
	}

	&.disabled {
		background: $grey-90;
		border-color: $grey-90;
		color: $grey-60;
		border-image: none;
		cursor: not-allowed;
	}
}

.button__secondary {
	background: $white;
	border-color: $grey-90;
	color: $blue-primary-35;
	box-shadow: $box-shadow-button-cancel;

	&:hover {
		background: linear-gradient(0deg, $grey-95 0%, rgba($grey-95, 0) 57.89%), $white;
		border-image: none;
	}

	&:focus {
		background: $white;
		border-color: $blue-primary-50;
		box-shadow: $box-shadow-button-focused;
	}

	&.disabled {
		background: $grey-95;
		border-color: $grey-90;
		color: $grey-60;
		box-shadow: none;
		cursor: not-allowed;
	}
}

.button__negative {
	background: $white;
	border-color: $red-90;
	color: $red-30;
	box-shadow: $box-shadow-button-remove;

	&:hover {
		background: linear-gradient(0deg, rgba($red-90, 0.24) 0%, rgba($red-90, 0) 57.89%), $white;
		border-image: none;
	}

	&:focus {
		background: $white;
		border-color: $red-50;
		box-shadow: 0px 0px 0px 2px $red-95;
	}

	&.disabled {
		background: $grey-95;
		border-color: $grey-90;
		color: $grey-60;
		box-shadow: none;
		cursor: not-allowed;
	}
}

.button__outlined {
	background: transparent;
	border-color: $white;
	color: $white;

	&:hover,
	&:focus {
		background: rgba($white, 0.25);
		border-image: none;
	}

	&:focus {
		box-shadow: $box-shadow-button-focused;
		border-image: none;
		border-color: $white;
	}

	&.disabled {
		border-image: none;
		background: $grey-95;
		border-color: $grey-90;
		color: $grey-60;
		cursor: not-allowed;
	}
}

.icon-component {
	display: flex;
	gap: 4px;
	align-items: center;
	padding: 6px 12px 6px 8px;

	&--big {
		gap: 6px;
		padding: 9px 16px 9px 12px;
	}

	&--qhd {
		gap: 8px;
		padding: 12px 24px 12px 16px;
	}
}

.with-secondary-icon-big {
	padding-right: 6px;
}

.with-secondary-icon-default {
	padding-right: 4px;
}

// should be at the end of this file to make sure that padding won’t be overridden by other classes
.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
}
