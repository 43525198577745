@import 'base.scss';

.wrapper {
	display: flex;
	align-items: center;
	padding: 5px 0;
	overflow: hidden;
}

.label {
	cursor: pointer;
	color: $grey-45;
	font-size: 12px;
	padding-left: 10px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	&--disabled {
		cursor: not-allowed;
	}

	&--unmarkable {
		cursor: default;
	}
}

.checkbox {
	display: flex;
	align-items: center;
	cursor: pointer;
	background: $white;
	position: relative;
	border-radius: 4px;
	border: 1px solid $grey-90;
	height: 20px;
	width: 20px;
	min-width: 20px;

	&--checked {
		background: $blue-primary-45;
		border-color: $blue-primary-45;
		color: $white;
	}

	&--disabled {
		background: $grey-95;
		border-color: $grey-90;
		color: $grey-90;
		cursor: not-allowed;
	}
}

.big {
	height: 24px;
	width: 24px;
	min-width: 24px;
}
