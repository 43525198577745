@import 'base.scss';

.settings {
	display: grid;
	gap: 20px;
	grid-template-columns: 1fr 1fr;
}

.number-of-rounds {
	background: $blue-primary-30;
	border-color: $blue-primary-30;
	color: rgba(255, 255, 255, 0.65);
	pointer-events: none;

	&:active,
	&:focus {
		border-color: $blue-primary-30;
	}
}
