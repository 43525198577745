@import 'themes/color';

$overlay-border-radius: 8px;
$overlay-header-height: 56px;

$flow-module-description-border-radius: 13px;
$flow-module-description-padding-vertical: 5px;
$flow-module-description-padding-horizontal: 12px;
$flow-module-description-padding: $flow-module-description-padding-vertical
	$flow-module-description-padding-horizontal;
$flow-detail-padding: 15px;
$flow-detail-padding-options: 8px;
$flow-detail-question-columns-gap: 8px;
$flow-detail-question-options-columns: 6px 1fr 40px 20px;
$flow-detail-image-choice-options-columns: 6px 48px 1fr 32px 20px;
$flow-detail-max-diff-options-columns: 6px 1fr 20px;
$flow-detail-max-diff-image-options-columns: 6px 48px 1fr 20px;

$template-builder-sidebar-width: 248px;
$template-builder-controlbar-height: 50px;
$template-builder-transition-options: 0.5s ease-in-out;
$template-builder-transition-floating-text: 0.25s ease-in-out;

$box-shadow-flow-module-description-hover: 0 1px 5px $grey-90;
$box-shadow-content: 0 4px 11px rgba(0, 0, 0, 0.3);
$box-shadow-button: 0 2px 4px rgba(#000, 0.1), 0 1px 1px rgba(#000, 0.15);
$box-shadow-button-soft: 0px 0.5px 2px 0px rgba($grey-10, 0.12);
$box-shadow-control-bar: 0px 0.5px 2px 0px rgba(10, 17, 40, 0.12);

$box-shadow-button-cancel: 0px 0.5px 2px rgba(10, 17, 40, 0.12);
$box-shadow-button-remove: 0px 1px 2px rgba($red-30, 0.16);
$box-shadow-button-focused: 0px 0px 0px 2px $blue-informative-95;

$box-shadow-header: 0px 1px 2px rgba($grey-10, 0.08);
$box-shadow-floating: 0px 1px 2px rgba(35, 48, 77, 0.08), 0px 2px 6px rgba(35, 48, 77, 0.12);
$box-shadow-uniform: 0px 0px 0px 3px rgba(54, 195, 248, 0.1);
$box-shadow-dropdown-menu: 0 0 0 1px $grey-95, 0 4px 11px $grey-95;

$control-bar-height: 48px;

$interactive-element-default-height: 32px;
$interactive-element-big-height: 38px;
$interactive-element-small-height: 24px;

$payment-log-action-gap: 8px;
$recalculation-icon-animation-settings: recalculation-icon-animation 1.5s
	cubic-bezier(0.5, 0.01, 0.42, 1) infinite;

@keyframes recalculation-icon-animation {
	0% {
		rotate: 360deg;
	}

	100% {
		rotate: 0deg;
	}
}
