@import 'base.scss';

.option-holder {
	align-items: center;
	color: $grey-10;
	cursor: pointer;
	display: flex;
	font-size: 12px;
	justify-content: space-between;
	min-height: 40px;
	padding: 0 10px;

	&__label {
		align-items: center;
		display: flex;
		max-width: 135px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&--with-icon {
			gap: 8px;
		}

		&--bigger-width {
			max-width: 305px;
			padding-left: 6px;
		}
		&--full-width {
			max-width: unset;
		}

		&--info {
			max-width: unset;
			text-overflow: unset;
			white-space: normal;
		}
	}

	&--go-back {
		color: $grey-45;
		justify-content: flex-start;
	}

	&--secondary {
		color: $grey-45;
	}

	&--alert {
		color: $red-30;
	}

	&--highlighted {
		color: $blue-primary-35;
	}

	&:hover {
		background-color: $grey-95;
	}

	&--info {
		color: $grey-45;
		cursor: default;
		padding: 12px 10px;
		border-bottom: 1px solid $grey-90;
		border-top: 1px solid $grey-90;

		&:hover {
			background-color: unset;
		}
	}

	&--disabled {
		color: $grey-60;
		cursor: not-allowed;

		&:hover {
			background-color: unset;
		}
	}
}

.icon {
	&--back {
		color: $grey-10;
		padding-right: 10px;
	}
}
