@import 'base.scss';

.toggle {
	border-radius: 5px;
	color: $grey-10;
	cursor: pointer;
	font-size: 12px;
	height: 30px;
	overflow: hidden;
	padding: 7px;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.disabled {
	cursor: not-allowed;
}

.study-design__condition {
	border: 1px solid $grey-90;
}
