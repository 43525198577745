@import 'base.scss';

.menu {
	background-color: $white;
	border-radius: 4px;
	border: 1px solid $grey-90;
	box-shadow: $box-shadow-floating;
	list-style-type: none;
	margin-bottom: 8px;
	width: 180px;
}

.section {
	display: flex;

	&__label {
		color: $grey-45;
		cursor: default;
		font-size: 12px;
		line-height: 16px;
		margin: 4px 6px 4px 4px;

		&--margin {
			margin: 4px 6px 4px 8px;
		}
	}

	&__border {
		border-top: 1px solid $grey-90;
		flex-grow: 1;
		margin: auto 0;
	}
}
