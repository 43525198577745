@import 'base.scss';

.conditions {
	&__label {
		display: block;
		font-size: 18px;
		padding: 10px 0;
	}

	&__case {
		display: grid;
		gap: 10px;
		grid-template-columns: 1fr 1fr;
	}

	&__add-new {
		padding-top: 5px;
	}
}
