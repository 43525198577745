@import 'base.scss';

.row {
	align-items: center;
	display: grid;
	gap: $flow-detail-question-columns-gap;
	grid-template-columns: $flow-detail-question-options-columns;
	padding-top: 6px;

	&--first {
		padding-top: 0;
	}

	&--image-choice {
		grid-template-columns: $flow-detail-image-choice-options-columns;
	}

	&__label {
		grid-column: 2;

		&--image-choice {
			grid-column: 3;
		}
	}

	&__code {
		cursor: pointer;
		display: flex;
		flex-wrap: nowrap;
		gap: 8px;
		grid-column: 3 / 5;

		&--image-choice {
			grid-column: 4 / 6;
		}
	}

	&__refresh {
		align-self: end;
		padding-bottom: 3px;
		display: flex;
	}
}

.label {
	padding-top: 0;
}
