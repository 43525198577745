@import 'base.scss';

.content {
	max-width: 800px;

	@media screen and (#{$max-width-736}) {
		border-radius: 0;
		width: 100vw;
		max-width: unset;
	}
}
