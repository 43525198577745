@import 'base.scss';

.wrapper {
  align-items: center;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.error-icon {
  display: flex;
  font-size: 24px;
  height: 24px;
  justify-content: center;
  padding-bottom: 40px;
  position: relative;
  text-align: center;

  &:after {
    @extend .icon-emoji-dead-full;

    color: $grey-45;
    font-size: 40px;
  }
}

.message {
  &__holder {
    padding: 20px 0;
  }

  &__title {
    padding-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  }

  &__text {
    font-size: 15px;
    line-height: 24px;
    white-space: pre-line;
    max-width: 400px;
  }
}
