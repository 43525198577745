@import 'base.scss';

@keyframes jump {
	0% {
		transform: translateY(0px);
	}
	28% {
		transform: translateY(-6px);
	}
	44% {
		transform: translateY(0px);
	}
}

@keyframes jumpbig {
	0% {
		transform: translateY(0px);
	}
	28% {
		transform: translateY(-12px);
	}
	44% {
		transform: translateY(0px);
	}
}

.wrapper {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	min-height: 12px;

	&--small {
		min-height: 15px;
	}

	&--big {
		min-height: 24px;

		.dot {
			animation: jumpbig 1s infinite ease-in-out;
			height: 12px;
			margin-left: 6px;
			width: 12px;
		}
	}
}

.dot {
	animation: jump 1s infinite ease-in-out;
	background-color: $grey-90;
	border-radius: 50%;
	height: 6px;
	list-style: none;
	margin-left: 4px;
	width: 6px;

	&:nth-child(1) {
		animation-delay: 200ms;
		margin-left: 0;
	}

	&:nth-child(2) {
		animation-delay: 300ms;
	}

	&:nth-child(3) {
		animation-delay: 400ms;
	}
}
