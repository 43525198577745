$icons: (
	icon-vertical-dots: '\e937',
	icon-union: '\e936',
	icon-video: '\e900',
	icon-image: '\e901',
	icon-add-new: '\e902',
	icon-close: '\e903',
	icon-dropdown: '\e904',
	icon-restore: '\e905',
	icon-search: '\e906',
	icon-sort-asc: '\e907',
	icon-sort-desc: '\e908',
	icon-study-list-answer-manager: '\e909',
	icon-study-list-data-downloader: '\e90a',
	icon-create: '\e90b',
	icon-study-list-reports: '\e90c',
	icon-study-list-segments: '\e90d',
	icon-study-state-archived: '\e90e',
	icon-study-state-awaiting-copy: '\e90f',
	icon-study-state-awaiting-delete: '\e910',
	icon-study-state-awaiting-pause: '\e911',
	icon-study-state-awaiting-solve: '\e912',
	icon-study-state-deleted: '\e913',
	icon-study-state-demo: '\e914',
	icon-study-state-draft: '\e915',
	icon-study-state-launched: '\e916',
	icon-study-state-paused: '\e917',
	icon-study-state-solved: '\e918',
	icon-study-state-test-with-bots: '\e919',
	icon-study-template-brand-positioning: '\e91a',
	icon-study-template-concept-testing: '\e91b',
	icon-study-template-customer-journey: '\e91c',
	icon-study-template-employee-satisfaction: '\e91d',
	icon-study-template-ideation: '\e91e',
	icon-study-template-nps: '\e91f',
	icon-study-template-product-naming: '\e920',
	icon-study-template-scratch: '\e921',
	icon-code: '\e922',
	icon-company: '\e923',
	icon-email: '\e924',
	icon-name: '\e925',
	icon-password-changed: '\e926',
	icon-password: '\e927',
	icon-phone-number: '\e928',
	icon-duplicate: '\e929',
	icon-archive: '\e92a',
	icon-arrow: '\e92b',
	icon-brackets: '\e92c',
	icon-browser-dead: '\e92d',
	icon-minus: '\e92e',
	icon-caret-right: '\e92f',
	icon-check: '\e930',
	icon-plus: '\e931',
	icon-help: '\e932',
	icon-study-list-interview: '\e933',
	icon-study-template-willingness-to-pay: '\e934',
	icon-launch: '\e935',
	icon-cross: '\e939',
	icon-dead: '\e93a',
	icon-drag: '\e93e',
	icon-emoji-dead-full: '\e93f',
	icon-emoji-dead-outline: '\e940',
	icon-emoji-sad: '\e941',
	icon-flow: '\e947',
	icon-graph: '\e949',
	icon-img-upload: '\e94a',
	icon-lock-locked: '\e94d',
	icon-lock-unlocked: '\e94e',
	icon-magnify: '\e94f',
	icon-menu: '\e950',
	icon-no-data: '\e951',
	icon-pause: '\e952',
	icon-pencil: '\e953',
	icon-pin: '\e954',
	icon-direction-left: '\e955',
	icon-question-mark: '\e956',
	icon-quota: '\e957',
	icon-sheets: '\e960',
	icon-shuffle: '\e961',
	icon-side-bars: '\e962',
	icon-trash: '\e967',
	icon-user-group: '\e96a',
	icon-knowledge-base: '\e96b',
	icon-loader: '\e96d',
	icon-percentage: '\e96e',
	icon-star: '\e96f',
	icon-direction-right: '\e970',
	icon-drag-vertical: '\e971',
	icon-jsfiddle: '\e972',
	icon-refresh: '\e973',
	icon-user: '\e974',
	icon-logout: '\e975',
	icon-settings: '\e976',
	icon-study-state-error: '\e97e',
	icon-arrow-new: '\e983',
	icon-filter: '\e985',
	icon-restore-study-cancel: '\e988',
	icon-restore-study-confirm: '\e989',
	icon-info: '\e98a',
	icon-expand-diagonal: '\e98b',
	icon-collapse-diagonal: '\e98c',
	icon-delete-study: '\e98e',
	icon-clipboard: '\e9b8',
	icon-loop2: '\ea2e',
);

@each $class, $icon in $icons {
	.#{$class} {
		&:after {
			content: $icon;
			font-size: 12px;
		}
	}
}

.icon-expand {
	@extend .icon-expand-diagonal;

	&:after {
		transform: translateY(-50%) rotate(135deg);
		top: 50%;
	}
}

.icon-collapse {
	@extend .icon-collapse-diagonal;

	&:after {
		transform: translateY(-50%) rotate(135deg);
		top: 50%;
	}
}

.icon-position-center {
	position: relative;

	&:after {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
	}
}

.rotate-animation {
	position: relative;

	&:after :local {
		position: absolute;
		animation: spin 1s linear infinite;
	}
}

.icon-caret-down {
	@extend .icon-caret-right;

	&:after {
		transform: rotate(90deg);
	}
}

.icon-caret-up {
	@extend .icon-caret-right;

	&:after {
		transform: rotate(270deg);
	}
}

.icon-caret-left {
	@extend .icon-caret-right;

	&:after {
		transform: rotate(180deg);
	}
}

.icon-loader {
	@extend .rotate-animation;
}

.icon-arrow-right {
	@extend .icon-arrow;

	&:after {
		transform: rotate(270deg);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
