@import 'base.scss';

.holder {
	background: rgba(0, 0, 0, 0.4);
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
}
