@import 'base.scss';

.content {
	background: $white;
	border-radius: $overlay-border-radius;
	box-shadow: $box-shadow-content;
	max-width: 800px;
	min-width: 400px;
	padding: 56px 48px 48px;
	position: relative;

	@media screen and (#{$max-width-736}) {
		border-radius: 0;
		width: 100vw;
		max-width: unset;
		min-width: unset;
		padding: 32px;
	}
}
