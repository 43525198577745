@import 'base.scss';

.badge {
	display: inline-block;
	border-radius: 4px;
	font-size: 12px;
	padding: 8px 10px;
	line-height: 16px;

	flex-shrink: 0;

	&--grey {
		background: $grey-95;
		color: $grey-45;
		border-color: $grey-45;
	}

	&--green {
		background: $green-95;
		color: $green-25;
		border-color: $green-25;
	}

	&--red {
		background: $red-95;
		color: $red-40;
		border-color: $red-40;
	}

	&--yellow {
		background: $yellow-95;
		color: $yellow-35;
		border-color: $yellow-35;
	}

	&--blue {
		background: $blue-informative-95;
		color: $blue-primary-35;
		border-color: $blue-primary-35;
	}

	&--beta {
		padding: 2px 4px;
		font-size: 10px;
		font-weight: 600;
		background: $green-95;
		color: $green-25;
		border-color: $green-25;
	}

	&--with-border {
		border: 1px solid;
	}
}
