@import 'base.scss';

.wrapper {
	background: $white;
	border-radius: 4px;
	border: 1px solid $grey-90;

	&--with-image {
		position: relative;
		grid-template-columns: none;
		width: 100%;
		padding: 7px 7px 7px 8px;
	}

	&--without-image {
		display: grid;
		grid-template-columns: 1fr 20px;
		min-height: 30px;
		line-height: 16px;
		padding: 7px;
	}
}

.text-area-wrapper {
	padding-bottom: 6px;
}

.text-area {
	background: none;
	font-size: 12px;
	min-height: 16px;

	&:active,
	&:focus,
	&:hover {
		border: none;
	}

	&--without-image {
		max-width: 250px;

		@media screen and (#{$max-width-736}) {
			max-width: 589px;
		}
	}
}

.line {
	background: $grey-60;
	position: absolute;
	width: inherit;
	height: 1px;
	left: 0;
}

.image-controls {
	position: relative;
	display: grid;
	grid-template-columns: 1fr 120px;
	align-items: center;
	padding-top: 7px;
	max-width: 275px;

	&__uploader {
		height: 32px;
		width: 32px;
	}
}

.image-placeholder {
	position: absolute;
}
