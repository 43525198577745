@import 'base.scss';

.wrapper {
	padding-bottom: 10px;
	margin-left: -$flow-detail-padding;
	margin-right: -$flow-detail-padding;
	padding-left: $flow-detail-padding-options;
	padding-right: $flow-detail-padding-options;

	&--dynamic {
		margin: 0;
		padding: 0;
	}
}

.none-of-these-toggle {
	padding-left: 14px;

	&--dynamic {
		padding-left: 0;
	}
}

.add {
	&__holder {
		align-items: center;
		display: grid;
		gap: $flow-detail-question-columns-gap;
		grid-template-columns: $flow-detail-question-options-columns;
		padding-top: 6px;

		&--image-choice {
			grid-template-columns: $flow-detail-image-choice-options-columns;
		}
	}

	&__button {
		align-items: center;
		background: $blue-primary-30;
		border-radius: 4px;
		color: $white;
		cursor: pointer;
		display: flex;
		font-size: 12px;
		gap: 8px;
		height: $interactive-element-default-height;
		justify-content: center;
		line-height: 16px;
		grid-column: 2 / 4;

		&--image-choice {
			grid-column: 2 / 5;
		}
	}
}
