@import 'base.scss';

.rule {
	padding: 16px 0;
	position: relative;
	border-top: 1px solid $grey-90;

	&--first {
		border: none;
		padding-top: 8px;
	}

	&__label {
		font-size: 12px;
		padding-right: 40px;
	}

	&__delete {
		position: absolute;
		top: 14px;
		right: 4px;
		cursor: pointer;

		&--first {
			top: 6px;
		}
	}
}

.split {
	display: grid;
	gap: 8px;
	grid-template-columns: 1fr 1fr;
}

.add {
	display: flex;
	justify-content: flex-end;
	padding-top: 10px;
	width: 100%;
}
