@import 'base.scss';

.upload,
.image {
	border-radius: 5px;
	border: 1px solid $white;
	cursor: pointer;
	overflow: hidden;
}

.upload {
	align-items: center;
	display: flex;
	height: 60px;
	justify-content: center;

	&--disabled {
		cursor: default;
	}
}

.image {
	object-fit: contain;
	position: relative;
	align-items: center;
	display: flex;
	justify-content: center;
	max-height: 300px;

	&--disabled {
		cursor: default;
	}

	&__change {
		align-items: center;
		background-color: $white;
		cursor: pointer;
		display: flex;
		inset: 0;
		justify-content: center;
		opacity: 0;
		position: absolute;
		color: $grey-10;

		&--disabled {
			display: none;
		}
	}

	&:hover {
		.image__change {
			opacity: 0.9;
		}
	}
}
