@import 'base.scss';

.refresh {
	&:after {
		@extend .icon-refresh;
	}
}

.preseeded-statement {
	padding: 2px 0;
	position: relative;

	&__input {
		padding-right: 25px;
	}

	&__remove {
		cursor: pointer;
		color: $grey-45;
		height: 15px;
		position: absolute;
		right: 5px;
		top: 50%;
		transform: translateY(-50%);
		width: 15px;

		&:after {
			@extend .icon-minus;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
