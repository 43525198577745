@import 'base.scss';

.holder {
	display: grid;
	grid-template: 1fr / 1fr 1fr;
}

.tab {
	position: relative;
	cursor: pointer;
	font-size: 14px;
	padding: 10px;
	text-align: center;

	&--message {
		background: $blue-primary-45;
	}

	&--dataset {
		background: $white;
		color: $blue-primary-35;
	}

	&--invalid {
		&:after {
			content: '';
			border: 5px solid $red-40;
			border-radius: 50%;
			height: 10px;
			left: 15px;
			top: 50%;
			transform: translateY(-50%);
			width: 10px;
		}
	}
}
