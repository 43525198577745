@import 'base.scss';

.expandable {
	cursor: pointer;
	font-size: 12px;
	padding: 15px 0 5px 0;
	display: flex;
	align-items: center;
	gap: 4px;
}

.icon {
	align-items: center;
	display: flex;
	flex-shrink: 0;
}

.label {
	min-width: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.invalid {
	background: $red-40;
	border-radius: 50%;
	display: inline-block;
	height: 10px;
	margin: 0 5px;
	width: 10px;
}
