/**
* color naming convention
* color-lightness: #hex
* https://www.figma.com/file/nFIlH2rPqaVWTSkVbQJ358/Styleguides?node-id=106%3A1485
*/

//do not forget to EXPORT new color, so we can use it in .js files

// white
$white: #fff;

// black / grey
$grey-95: #f3f6fc;
$grey-90: #dbe2f0;
$grey-60: #8091b1;
$grey-45: #5c6b8a;
$grey-10: #0a1128;

// blue
$blue-primary-95: var(--PRIMARY_95);
$blue-primary-90: var(--PRIMARY_90);
$blue-primary-50: var(--PRIMARY_50);
$blue-primary-45: var(--PRIMARY_45);
$blue-primary-35: var(--PRIMARY_35);
// $blue-primary-30 is for edge cases only
$blue-primary-30: var(--PRIMARY_30);

$blue-informative-95: #ebfaff;
$blue-informative-90: #ccf1ff;
$blue-informative-50: #0057ff;
$blue-informative-40: #1f4ebd;
$blue-informative-35: #204597;

// green
$green-95: #e3fdf6;
$green-85: #c4f2e6;
$green-45: #0ad69d;
$green-40: #09c38f;
$green-35: #05a87b;
$green-30: #05946c;
$green-25: #008062;

// red
$red-95: #fce9ed;
$red-90: #f6cbd5;
$red-50: #f00f39;
$red-40: #c80428;
$red-30: #9f0420;

// yellow
$yellow-95: #fff3e0;
$yellow-85: #f6e1c0;
$yellow-55: #ffba1c;
$yellow-35: #b26800;
$yellow-25: #804a00;

// orange
$orange-95: #fef4ec;
$orange-90: #fadec7;
$orange-60: #ff8e3c;
$orange-50: #e56f1a;
$orange-35: #a14e12;

// teal
$teal-95: #eaf8fa;
$teal-85: #c7ebf0;
$teal-60: #5fc7d5;
$teal-45: #35a2b1;
$teal-30: #236c76;

// purple
$purple-95: #faeefd;
$purple-90: #eec3f9;
$purple-60: #bd5dd5;
$purple-50: #ac35ca;
$purple-35: #79258e;

// pink
$pink-95: #fee6ea;
$pink-90: #fdc4ce;
$pink-60: #ef476f;
$pink-50: #de214e;
$pink-30: #85142f;

// dark mode
$dark-mode-blue-15: #151e3c;
$dark-mode-blue-25: #232d4d;
$dark-mode-blue-30: #313b5e;
$dark-mode-blue-45: #5a668c;
$dark-mode-blue-65: #8995bd;
$dark-mode-blue-75: #abb4d4;

// --------------------------
// custom colors
// --------------------------
// VISUAL FLOW BRANCH COLORS
$orangeMedium: #f15a24;
$brownDark: #694a2b;
$seaGreenDark: #007f76;
$seaGreen: #00a99d;
$purpleMedium: #66318f;

// --------------------------
// rgba
// --------------------------
$box-shadow-color: rgba(#000, 0.25);
$input-background: rgba(#000, 0.1);

// --------------------------
// STUDY STATE
// --------------------------
$study-state-draft: $grey-45;
$study-state-draft-bg: $grey-95;
$study-state-draft-icon: $grey-60;

$study-state-solved: $green-25;
$study-state-solved-bg: $green-95;
$study-state-solved-icon: $green-35;

$study-state-paused: $yellow-35;
$study-state-paused-bg: $yellow-95;
$study-state-paused-icon: $orange-50;

$study-state-archived: $grey-45;
$study-state-archived-bg: $grey-95;
$study-state-archived-icon: $grey-60;

$study-state-error: $red-40;
$study-state-error-bg: $red-95;
$study-state-error-icon: $red-50;

$study-state-launched: $blue-informative-35;
$study-state-launched-bg: $blue-informative-95;
$study-state-launched-icon: $blue-informative-40;

$study-state-demo: $blue-informative-35;
$study-state-demo-bg: $blue-informative-95;
$study-state-demo-icon: $blue-informative-50;

// --------------------------
// CREATE STUDY TEMPLATES
// --------------------------
$template-product-naming-color: $blue-informative-50;
$template-product-naming-bg: $blue-informative-95;

$template-ideation-color: $yellow-55;
$template-ideation-bg: $yellow-95;

$template-nps-color: $pink-60;
$template-nps-bg: $pink-95;

$template-concept-testing-color: $red-50;
$template-concept-testing-bg: $red-95;

$template-employee-satisfaction-color: $green-35;
$template-employee-satisfaction-bg: $green-95;

$template-willingness-to-pay-color: $teal-60;
$template-willingness-to-pay-bg: $teal-95;

$template-brand-positioning-color: $purple-60;
$template-brand-positioning-bg: $purple-95;

$template-customer-journey-color: $orange-60;
$template-customer-journey-bg: $orange-95;

$template-scratch-color: $grey-60;
$template-scratch-bg: $grey-95;

//export to colors.js
:export {
	//white
	WHITE: $white;

	// black / grey;
	GREY_95: $grey-95;
	GREY_90: $grey-90;
	GREY_60: $grey-60;
	GREY_45: $grey-45;
	GREY_10: $grey-10;

	//blue
	PRIMARY_95: $blue-primary-95;
	PRIMARY_90: $blue-primary-90;
	PRIMARY_50: $blue-primary-50;
	PRIMARY_45: $blue-primary-45;
	PRIMARY_35: $blue-primary-35;

	INFORMATIVE_95: $blue-informative-95;
	INFORMATIVE_90: $blue-informative-90;
	INFORMATIVE_50: $blue-informative-50;
	INFORMATIVE_40: $blue-informative-40;
	INFORMATIVE_35: $blue-informative-35;

	//green
	GREEN_95: $green-95;
	GREEN_85: $green-85;
	GREEN_45: $green-45;
	GREEN_40: $green-40;
	GREEN_35: $green-35;
	GREEN_30: $green-30;
	GREEN_25: $green-25;

	//red
	RED_95: $red-95;
	RED_90: $red-90;
	RED_50: $red-50;
	RED_40: $red-40;
	RED_30: $red-30;

	//yellow
	YELLOW_95: $yellow-95;
	YELLOW_85: $yellow-85;
	YELLOW_55: $yellow-55;
	YELLOW_35: $yellow-35;
	YELLOW_25: $yellow-25;

	//orange
	ORANGE_95: $orange-95;
	ORANGE_90: $orange-90;
	ORANGE_60: $orange-60;
	ORANGE_50: $orange-50;
	ORANGE_35: $orange-35;

	//teal
	TEAL_95: $teal-95;
	TEAL_85: $teal-85;
	TEAL_60: $teal-60;
	TEAL_45: $teal-45;
	TEAL_30: $teal-30;

	//purple
	PURPLE_95: $purple-95;
	PURPLE_90: $purple-90;
	PURPLE_60: $purple-60;
	PURPLE_50: $purple-50;
	PURPLE_35: $purple-35;

	//pink
	PINK_95: $pink-95;
	PINK_90: $pink-90;
	PINK_60: $pink-60;
	PINK_50: $pink-50;
	PINK_30: $pink-30;

	// dark mode
	DARK_MODE_15: $dark-mode-blue-15;
	DARK_MODE_25: $dark-mode-blue-25;
	DARK_MODE_30: $dark-mode-blue-30;
	DARK_MODE_45: $dark-mode-blue-45;
	DARK_MODE_65: $dark-mode-blue-65;
	DARK_MODE_75: $dark-mode-blue-75;

	// --------------------------
	// custom colors
	// --------------------------
	// VISUAL FLOW BRANCH COLORS
	ORANGE_MEDIUM: $orangeMedium;
	BROWN_DARK: $brownDark;
	SEA_GREEN_DARK: $seaGreenDark;
	SEA_GREEN: $seaGreen;
	PURPLE_MEDIUM: $purpleMedium;

	// --------------------------
	// rgba
	// --------------------------
	BOX_SHADOW: $box-shadow-color;
	INPUT_BACKGROUND: $input-background;

	// --------------------------
	// STUDY STATE
	// --------------------------

	STUDY_STATE_DRAFT: $study-state-draft;
	STUDY_STATE_DRAFT_BG: $study-state-draft-bg;
	STUDY_STATE_DRAFT_ICON: $study-state-draft-icon;

	STUDY_STATE_SOLVED: $study-state-solved;
	STUDY_STATE_SOLVED_BG: $study-state-solved-bg;
	STUDY_STATE_SOLVED_ICON: $study-state-solved-icon;

	STUDY_STATE_PAUSED: $study-state-paused;
	STUDY_STATE_PAUSED_BG: $study-state-paused-bg;
	STUDY_STATE_PAUSED_ICON: $study-state-paused-icon;

	STUDY_STATE_ARCHIVED: $study-state-archived;
	STUDY_STATE_ARCHIVED_BG: $study-state-archived-bg;
	STUDY_STATE_ARCHIVED_ICON: $study-state-archived-icon;

	STUDY_STATE_ERROR: $study-state-error;
	STUDY_STATE_ERROR_BG: $study-state-error-bg;
	STUDY_STATE_ERROR_ICON: $study-state-error-icon;

	STUDY_STATE_LAUNCHED: $study-state-launched;
	STUDY_STATE_LAUNCHED_BG: $study-state-launched-bg;
	STUDY_STATE_LAUNCHED_ICON: $study-state-launched-icon;

	STUDY_STATE_DEMO: $study-state-demo;
	STUDY_STATE_DEMO_BG: $study-state-demo-bg;
	STUDY_STATE_DEMO_ICON: $study-state-demo-icon;

	// --------------------------
	// CREATE STUDY TEMPLATES
	// --------------------------
	TEMPLATE_PRODUCT_NAMING_COLOR: $template-product-naming-color;
	TEMPLATE_PRODUCT_NAMING_BG: $template-product-naming-bg;

	TEMPLATE_IDEATION_COLOR: $template-ideation-color;
	TEMPLATE_IDEATION_BG: $template-ideation-bg;

	TEMPLATE_NPS_COLOR: $template-nps-color;
	TEMPLATE_NPS_BG: $template-nps-bg;

	TEMPLATE_CONCEPT_TESTING_COLOR: $template-concept-testing-color;
	TEMPLATE_CONCEPT_TESTING_BG: $template-concept-testing-bg;

	TEMPLATE_EMPLOYEE_SATISFACTION_COLOR: $template-employee-satisfaction-color;
	TEMPLATE_EMPLOYEE_SATISFACTION_BG: $template-employee-satisfaction-bg;

	TEMPLATE_WILLINGNESS_TO_PAY_COLOR: $template-willingness-to-pay-color;
	TEMPLATE_WILLINGNESS_TO_PAY_BG: $template-willingness-to-pay-bg;

	TEMPLATE_BRAND_POSITIONING_COLOR: $template-brand-positioning-color;
	TEMPLATE_BRAND_POSITIONING_BG: $template-brand-positioning-bg;

	TEMPLATE_CUSTOMER_JOURNEY_COLOR: $template-customer-journey-color;
	TEMPLATE_CUSTOMER_JOURNEY_BG: $template-customer-journey-bg;

	TEMPLATE_SCRATCH_COLOR: $template-scratch-color;
	TEMPLATE_SCRATCH_BG: $template-scratch-bg;
}
