@import 'base.scss';

.caret-right {
	display: inline-block;
	position: relative;
	height: 10px;

	&:after {
		@extend .icon-arrow;
		color: $grey-60;
		transform: rotate(270deg);
		left: 10px;
	}
}

.nextStep-holder {
	font-size: 12px;
	padding: 10px 0;
}

.nextStep-info-holder {
	padding-top: 10px;
}

.nextStep-info {
	display: inline-block;
	padding-left: 30px;
}

.nextStep-type {
	display: inline-block;
	font-weight: 700;
}

.invalid {
	color: $red-40;
}

.nextStep-label {
	display: inline;
}

.nextStep-id {
	padding: 10px 30px 0;
}
