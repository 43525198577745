@import 'base.scss';

.wrapper {
	align-items: center;
	cursor: pointer;
	display: grid;
	grid-template-columns: 12px 1fr;
	padding: 10px;

	&:hover {
		background: $grey-95;
	}
}

.label {
	font-size: 12px;
	padding-left: 5px;
	text-align: left;
}

.checkbox {
	position: relative;
	background: $grey-90;
	border-radius: 3px;
	height: 12px;
	width: 12px;

	&--checked {
		background: $blue-primary-45;

		&:after {
			@extend .icon-check;
			color: $white;
			font-size: 6px;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
