@import 'base.scss';

.wrapper {
	align-items: center;
	padding: 10px 0;
}

.left-to-right,
.right-to-left {
	display: flex;
}

.left-to-right {
	flex-direction: row;
	gap: 10px;
}

.right-to-left {
	flex-direction: row-reverse;
	gap: 8px;
}

.label {
	cursor: pointer;
	font-size: 12px;
	display: flex;
	gap: 4px;
	align-items: center;
}

.checkbox {
	background-color: $grey-95;
	border: 1px solid $grey-90;
	border-radius: 16px;
	cursor: pointer;
	flex-shrink: 0;
	height: 20px;
	width: 40px;
	position: relative;
	transition: opacity 0.5s;

	&:after {
		background: $grey-60;
		border-radius: 17px;
		content: '';
		height: 16px;
		width: 16px;
		left: 3px;
		top: 50%;
		transform: translateY(-50%);
		transition: left 0.5s;
	}

	&--dark-theme {
		background: $input-background;
		border: 1px solid transparent;

		&:after {
			background: $white;
		}
	}

	&--checked {
		background: $blue-primary-50;
		border: 1px solid $blue-primary-50;

		&:after {
			background: $white;
			left: 20px;
		}
	}

	&--big {
		height: 24px;
		width: 44px;

		&:after {
			height: 20px;
			width: 20px;
		}
	}

	&--disabled {
		background: $grey-95;
		border: 1px solid $grey-90;
		cursor: not-allowed;

		&:after {
			background: $input-background;
		}
	}

	&--disabled-dark-theme {
		background: $input-background;
		border: 1px solid transparent;
		cursor: not-allowed;
		opacity: 0.5;

		&:after {
			background: $white;
		}
	}
}

.description {
	color: $grey-45;
	font-size: 12px;
	line-height: 16px;
	margin-top: 2px;
}

.titles {
	display: flex;
	flex-direction: column;
}
