@import 'base.scss';

.hint {
	display: flex;
	align-items: center;
	font-size: 12px;
	padding-top: 8px;
	padding-bottom: 4px;
	gap: 4px;
	cursor: help;

	&--matrix {
		padding-bottom: 0;
	}
}

.tip {
	// max-width: 300px;
	white-space: pre;
}
